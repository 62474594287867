import { t } from '@lingui/macro';
import { getToken } from '@luminovo/auth';
import { formatDecimal, formatLongDateTime, formatRelativeTime, isPresent, pick, transEnum } from '@luminovo/commons';
import {
    createColumnHelper,
    Flexbox,
    generateFilterRequest,
    getOptionCount,
    Tag,
    TanStackTable,
    Text,
    Tooltip,
    useNavigate,
    useTanStackTablePagination,
    useTanStackTableState,
} from '@luminovo/design-system';
import {
    AssemblyIndustry,
    AssemblyOverviewAggregationDTO,
    AssemblyOverviewDTO,
    AssemblyOverviewFilterRequest,
    AssemblyOverviewFilterRequestRuntype,
    CustomerDTO,
    http,
    InventoryQuantityDTO,
    UserType,
} from '@luminovo/http-client';
import { assemblyIndustryTranslations } from '@luminovo/manufacturing-core';
import { Box } from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useCommunicationsDrawer, ViewCommentsButton } from '../../components/CommentsDrawer';
import { useCurrentUserDetailsContext, useUserType } from '../../components/contexts/CurrentUserDetailsContext';
import { useCustomers } from '../../resources/customer/customerHandler';
import { httpQueryKey } from '../../resources/http/httpQueryKey';
import { useHasCustomers } from '../../utils/featureFlags';
import { route } from '../../utils/routes';

type AssemblyOverviewTableSharedContext = {
    customers: CustomerDTO[];
    aggregations?: AssemblyOverviewAggregationDTO[];
};

// Component without communications drawer
function AssemblyTableWithoutComms() {
    const { userType } = useCurrentUserDetailsContext();
    const { hasCustomers } = useHasCustomers();
    const { data: customers = [] } = useCustomers();
    const navigate = useNavigate();

    const { tableState } = useTanStackTableState({
        columnsKey: 'assembly-overview-table',
        enableColumnOrdering: true,
        enableColumnHiding: true,
    });

    const columns = React.useMemo(() => {
        if (userType === UserType.Customer) {
            return defaultCustomerUserColumns;
        }
        return hasCustomers ? [customerColumn, ...defaultColumns] : defaultColumns;
    }, [hasCustomers, userType]);

    const {
        data: infiniteData,
        fetchNextPage,
        isFetchingNextPage,
        isLoading,
    } = useAssemblyOverview(generateFilterRequest(tableState, columns, AssemblyOverviewFilterRequestRuntype));

    const { data, aggregations, totalCount } = React.useMemo(() => {
        return {
            data: infiniteData?.pages.flatMap((data): AssemblyOverviewRow[] =>
                data.page.map(
                    (row): AssemblyOverviewRow => ({
                        ...row,
                        inventory_quantity: row.inventory_quantities.reduce((sum, item) => sum + item.quantity, 0),
                    }),
                ),
            ),
            aggregations: infiniteData?.pages[0]?.aggregations,
            totalCount: infiniteData?.pages[0]?.total_count,
        };
    }, [infiniteData]);

    const sharedContext: AssemblyOverviewTableSharedContext = {
        customers,
        aggregations,
    };

    const { table } = useTanStackTablePagination<AssemblyOverviewDTO, AssemblyOverviewTableSharedContext>({
        columns,
        data,
        tableState,
        fetchNextPage,
        isFetchingNextPage,
        totalCount,
        sharedContext,
        enableExcelExport: true,
        isLoading,
        onRowClick:
            userType === UserType.Customer
                ? undefined
                : (row) => {
                      const assembly = row.original;
                      if (assembly.rfq) {
                          return navigate(
                              route(
                                  '/assemblies/:assemblyId/dashboard',
                                  { assemblyId: assembly.id },
                                  { rfqId: assembly.rfq, tab: undefined, isMonitoringOpen: undefined },
                              ),
                          );
                      }
                      navigate(route('/assemblies/:assemblyId/dashboard', { assemblyId: assembly.id }));
                  },
    });

    return (
        <TanStackTable
            table={table}
            size={'medium'}
            enableMenuBar={{ globalSearch: false, exportExcelButton: true }}
            MenuBarTitle={MenuBarTitle}
        />
    );
}

// Component with communications drawer
function AssemblyTableWithComms({ assemblyId }: { assemblyId: string }) {
    const userType = useUserType();
    useCommunicationsDrawer({
        threads:
            userType === UserType.Internal
                ? [
                      {
                          commentType: 'Assembly',
                          category: 'Internal',
                          typeIds: assemblyId,
                          assemblyId,
                      },
                      {
                          commentType: 'Assembly',
                          category: 'Public',
                          typeIds: assemblyId,
                          assemblyId,
                      },
                  ]
                : [
                      {
                          commentType: 'Assembly',
                          category: 'Public',
                          typeIds: assemblyId,
                          assemblyId,
                      },
                  ],
    });

    return <AssemblyTableWithoutComms />;
}

// Main component that decides which version to render
export default function AssemblyOverviewTable() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const assemblyId = searchParams.get('assemblyId');
    const openCommentsDrawer = searchParams.get('openCommentsDrawer') === 'true';

    // Return appropriate component based on URL params
    if (assemblyId && openCommentsDrawer) {
        return <AssemblyTableWithComms assemblyId={assemblyId} />;
    }

    return <AssemblyTableWithoutComms />;
}

function MenuBarTitle() {
    return (
        <Flexbox gap={'8px'}>
            <Text variant="h3">{t`Assemblies`}</Text>
        </Flexbox>
    );
}

function useAssemblyOverview(filters: AssemblyOverviewFilterRequest[]) {
    const customFilter: AssemblyOverviewFilterRequest = {
        field: 'Custom',
        operator: 'equalsAny',
        parameter: ['TopLevelAssemblies', 'AssembliesWithDemand', 'ImportedAssemblies'],
    };

    const requestBody = {
        page_size: 100,
        filters: [...filters, customFilter],
        page_params: undefined,
    };

    return useInfiniteQuery({
        queryKey: httpQueryKey('POST /assemblies/paginated', { requestBody }),
        queryFn: async ({ pageParam }) => {
            return await http(
                'POST /assemblies/paginated',
                {
                    requestBody: {
                        ...requestBody,
                        page_params: pageParam,
                    },
                },
                getToken(),
            );
        },
        initialPageParam: undefined as unknown | undefined,
        getNextPageParam: (data) => data.page_params,
    });
}

interface Identifier {
    value: string;
    revision: string | null;
}

export function formatIdentifier(identifier: Identifier | null, options: { ifAbsent: string } = { ifAbsent: '-' }) {
    if (!isPresent(identifier)) {
        return options.ifAbsent;
    }
    if (identifier.revision) {
        return `${identifier.value} • ${identifier.revision}`;
    }
    return identifier.value;
}

function AssemblyCommentsButton({ assemblyId }: { assemblyId: string }) {
    const userType = useUserType();

    const { openDrawer } = useCommunicationsDrawer({
        threads:
            userType === UserType.Internal
                ? [
                      {
                          commentType: 'Assembly',
                          category: 'Internal',
                          typeIds: assemblyId,
                          assemblyId,
                      },
                      {
                          commentType: 'Assembly',
                          category: 'Public',
                          typeIds: assemblyId,
                          assemblyId,
                      },
                  ]
                : [
                      {
                          commentType: 'Assembly',
                          category: 'Public',
                          typeIds: assemblyId,
                          assemblyId,
                      },
                  ],
    });

    return (
        <ViewCommentsButton
            eventEntity={{ type: 'Assembly', data: assemblyId }}
            onClick={() => openDrawer()}
            iconButtonOnly={true}
            size={'small'}
        />
    );
}

export interface AssemblyOverviewRow extends AssemblyOverviewDTO {
    inventory_quantity: number | undefined;
}

const columnHelper = createColumnHelper<AssemblyOverviewRow, AssemblyOverviewTableSharedContext>();

const designatorColumn = columnHelper.text('designator', {
    id: 'Name',
    size: 160,
    enableSorting: false,
    label: () => t`Name`,
    cell: (item) => item.getValue(),
});

const ipnColumn = columnHelper.text((row) => formatIdentifier(row.ipn), {
    id: 'Ipn',
    size: 160,
    label: () => t`IPN`,
    enableSorting: false,
    cell: (item) => item.getValue(),
});

const customerColumn = columnHelper.enum('customer', {
    id: 'Customer',
    size: 160,
    label: () => t`Customer`,
    options: ({ sharedContext }) =>
        sharedContext.customers.map(
            (c) => pick(c, ['id', 'name', 'customer_number']) as AssemblyOverviewDTO['customer'],
        ),
    getOptionLabel: (option) => option?.name ?? '-',
    getOptionCount: (option, { aggregations }) => getOptionCount(option, aggregations, 'Customer', (o) => o?.id),
    getOptionKey: (option) => option?.id ?? '-',
    cell: (item) => item.getValue()?.name ?? '-',
    filterValueTransform: (value) => value.map((v) => v?.id),
    enableSorting: false,
});

const createdAtColumn = columnHelper.date('created_at', {
    id: 'CreatedAt',
    size: 120,
    label: () => t`Creation date`,
    cell: (item) => {
        return (
            <Tooltip title={formatLongDateTime(item.getValue())}>
                <Text variant="inherit">{formatRelativeTime(item.getValue())}</Text>
            </Tooltip>
        );
    },
    enableSorting: false,
    enableColumnFilter: false,
});

const updatedAtColumn = columnHelper.date('updated_at', {
    id: 'UpdatedAt',
    size: 120,
    label: () => t`Updated at`,
    cell: (item) => {
        return (
            <Tooltip title={formatLongDateTime(item.getValue())}>
                <Text variant="inherit">{formatRelativeTime(item.getValue())}</Text>
            </Tooltip>
        );
    },
    enableSorting: false,
    enableColumnFilter: false,
});

const commentColumn = columnHelper.action({
    id: 'Comment',
    size: 40,
    enableSorting: false,
    enableColumnFilter: false,
    enableOnRowClick: false,
    cell: (item) => <AssemblyCommentsButton assemblyId={item.row.original.id} />,
});

const cpnColumn = columnHelper.text((row) => formatIdentifier(row.cpn), {
    id: 'Cpn',
    size: 160,
    label: () => t`CPN`,
    enableSorting: false,
    cell: (item) => item.getValue(),
    enableColumnFilter: false,
});

const inventoryQuantityColumn = columnHelper.number('inventory_quantity', {
    id: 'InventoryQuantity',
    size: 160,
    label: () => t`Inventory quantity`,
    cell: (item) => {
        const row = item.row.original;
        return (
            <Tooltip variant={'white'} title={<InventoryTooltip inventoryQuantities={row.inventory_quantities} />}>
                <Text variant="inherit">{item.getValue()}</Text>
            </Tooltip>
        );
    },
    enableColumnFilter: false,
});

function InventoryTooltip({ inventoryQuantities }: { inventoryQuantities: InventoryQuantityDTO[] }) {
    return (
        <Box display="grid" padding="12px" gridTemplateColumns={'auto 1fr'} columnGap={'24px'} rowGap={'8px'}>
            {inventoryQuantities.map((item) => (
                <React.Fragment key={item.site_name}>
                    <Text variant="body-small-semibold">{item.site_name}</Text>
                    <Text variant="body-small" style={{ textAlign: 'end' }}>
                        {formatDecimal(item.quantity)}
                    </Text>
                </React.Fragment>
            ))}
        </Box>
    );
}

const defaultColumns = [
    designatorColumn,
    ipnColumn,
    cpnColumn,
    columnHelper.enum('industry', {
        id: 'Industry',
        size: 120,
        label: () => t`Industry`,
        getOptionLabel: (option) => transEnum(option, assemblyIndustryTranslations),
        enableAdvancedFilters: false,
        options: Object.values(AssemblyIndustry),
        getOptionCount: (option, { aggregations }) => getOptionCount(option, aggregations, 'Industry'),
        enableSorting: false,
        cell: (item) => transEnum(item.getValue(), assemblyIndustryTranslations),
    }),
    columnHelper.enum('origin.type', {
        id: 'Origin',
        size: 120,
        label: () => t`Origin`,
        getOptionLabel: (option) => {
            switch (option) {
                case 'ApiImport':
                    return t`API import`;
                case 'BomImport':
                    return t`BOM import`;
                case 'Manual':
                    return t`Manually created`;
            }
        },
        enableAdvancedFilters: false,
        options: ['ApiImport', 'Manual'],
        getOptionCount: (option, { aggregations }) =>
            getOptionCount(option, aggregations, 'Origin', (o) => ({ type: o })),
        enableSorting: false,
        filterValueTransform: (value) => value.map((v) => ({ type: v })),
        cell: (item) => {
            switch (item.getValue()) {
                case 'ApiImport':
                    return <Tag color={'primary'} label={t`API import`} attention="low" />;
                case 'Manual':
                    return <Tag color={'neutral'} label={t`Manually created`} attention="low" />;
            }
        },
    }),
    inventoryQuantityColumn,
    createdAtColumn,
    updatedAtColumn,
    commentColumn,
];

const defaultCustomerUserColumns = [
    designatorColumn,
    ipnColumn,
    cpnColumn,
    createdAtColumn,
    inventoryQuantityColumn,
    commentColumn,
];
